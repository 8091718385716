import { SimpleGrid, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { fetchStats } from '../../../utils/statsApi';

const NewHomeStats = () => {
  const statsQuery = useQuery({
    queryKey: ['stats'],
    queryFn: async () => {
      const response = await fetchStats();
      return response;
    }
  });
  const statsResult = statsQuery.data;
  const stats = useMemo(() => {
    return [
      // {
      //   title: 'Liquidity Source',
      //   value: '-'
      // },
      {
        title: 'Total Volume',
        value: statsResult ? Number(statsResult.d3xTotalVolume).toFixed(0) : '-'
      },
      {
        title: 'Total Wallets',
        value: statsResult ? statsResult.addressCount : '-'
      },
      {
        title: 'Total Trades',
        value: statsResult ? statsResult.d3xTradeCount : '-'
      }
    ];
  }, [statsResult]);
  return (
    <SimpleGrid width="100%" columns={[1, null, 3]} spacing={5}>
      {stats.map((item) => (
        <Stat
          key={item.title}
          color="white"
          bgColor={'#07052E'}
          paddingY="15px"
          borderRadius="8px"
          userSelect={'none'}
          _hover={{ bgColor: '#19218C' }}>
          <StatNumber textAlign="center" marginBottom="10px" fontSize="24px" fontWeight={600}>
            {item.value}
          </StatNumber>
          <StatLabel textAlign="center" fontSize="14px" fontWeight={600} color="#777E90">
            {item.title}
          </StatLabel>
        </Stat>
      ))}
    </SimpleGrid>
  );
};

export default NewHomeStats;
